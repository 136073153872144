import request from '@/tools/request';
import { Control, Response } from '../types/edit';

export const create: Control = data => {
  return request({
    url: '/demo/index/add',
    data,
  }) as Promise<Response>;
};

export const edit: Control = data => {
  return request({
    url: '/demo/index/edit',
    data,
  }) as Promise<Response>;
};
