
import { defineComponent, ref } from 'vue';
import { ElButton, ElMessage } from 'element-plus';
import { create, edit } from '../api/edit';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Edit',
  setup() {
    const content = ref('');
    const route = useRoute();
    const editId = route.params.id as string | undefined;

    const save = async () => {
      const { status } = editId
        ? await edit({ id: editId, content: '123' })
        : await create({ content: '123' });
      if (status) {
        ElMessage({
          type: 'success',
          message: '保存成功',
        });
      }
    };

    return {
      content,
      save,
    };
  },
  components: { ElButton },
});
